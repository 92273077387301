import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import ConsolidatedStatValueTag from 'components/PlayerPageController/PlayerStatisticsBlock/ConsolidatedStatValueTag';
import { AppStateType } from 'reducers';

const MultitoolConsolidatedStatValueTag = ({
  stat,
  showPth,
  selectedMetricFilters,
  makeLinkAndNavigate,
}: {
  stat: any;
  showPth: boolean;
  selectedMetricFilters: any;
  makeLinkAndNavigate: any;
}) => {
  const {
    loadingPercentiles_stats,
    selectedPosition_stats,
    selectedStat,
    selectedFromFieldCells,
    selectedToFieldCells,
    displayedComets,
    matchedPointEvents,
    multitoolEventsLoading,
    selectedEventsOrComets,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsSectionReducer);
  const [overriddenPercent, setOverriddenPercent] = useState<string | null>(
    null,
  );
  const [overriddenValue, setOverriddenValue] = useState<string | null>(null);
  const [localShowPth, setLocalShowPth] = useState<boolean>(showPth);
  useEffect(() => {
    setLocalShowPth(showPth);
  }, [showPth]);
  useEffect(() => {
    // console.log(selectedFromFieldCells);
    if (selectedStat?.key === stat?.key) {
      if (
        selectedMetricFilters.includes(`${stat?.key}_success`) ||
        selectedMetricFilters.includes(`${stat?.key}_fail`)
      ) {
        setOverriddenPercent(null);
      } else if (
        selectedFromFieldCells.length === 0 &&
        selectedToFieldCells.length === 0
      ) {
        setLocalShowPth(true);
        setOverriddenPercent(null);
      } else if (selectedFromFieldCells.length || selectedToFieldCells.length) {
        let success;
        let total;
        if (selectedEventsOrComets.length) {
          success = displayedComets.filter(
            (m: any) =>
              m.result === 'Success' && selectedEventsOrComets.includes(m.id),
          ).length;
          total = selectedEventsOrComets.length;
        } else {
          success = displayedComets.filter(
            (m: any) => m.result === 'Success',
          ).length;
          total = displayedComets.length;
        }
        if (total) {
          // setLocalShowPth(false);
          setOverriddenPercent(Math.round((success / total) * 100).toString());
        }
        setLocalShowPth(false);
      } else {
        setLocalShowPth(true);
        setOverriddenPercent(null);
      }
      if (selectedEventsOrComets.length) {
        const success = displayedComets.filter(
          (m: any) =>
            m.result === 'Success' && selectedEventsOrComets.includes(m.id),
        ).length;
        const total = selectedEventsOrComets.length;
        setOverriddenPercent(Math.round((success / total) * 100).toString());
        setLocalShowPth(false);
      }
    } else {
      // setLocalShowPth(true);
      setOverriddenPercent(null);
    }
  }, [
    selectedMetricFilters,
    selectedEventsOrComets,
    selectedStat,
    selectedFromFieldCells,
    selectedToFieldCells,
    displayedComets,
  ]);
  useEffect(() => {
    if (selectedStat?.as_point && stat && stat.key === selectedStat?.key) {
      if (
        selectedFromFieldCells.length > 0 &&
        selectedEventsOrComets.length === 0 &&
        !multitoolEventsLoading
      ) {
        setOverriddenValue(matchedPointEvents.length.toString());
      } else if (selectedEventsOrComets.length > 0) {
        setOverriddenValue(selectedEventsOrComets.length.toString());
      } else {
        setOverriddenValue(null);
      }
    } else {
      if (stat?.key === selectedStat?.key) {
        if (multitoolEventsLoading) {
          return;
        }
        if (selectedEventsOrComets.length > 0) {
          setOverriddenValue(selectedEventsOrComets.length.toString());
        } else {
          setOverriddenValue(displayedComets.length.toString());
        }
      } else {
        setOverriddenValue(null);
      }
    }
  }, [
    selectedStat,
    selectedFromFieldCells,
    selectedEventsOrComets,
    matchedPointEvents,
    displayedComets,
  ]);
  return (
    <ConsolidatedStatValueTag
      showPth={localShowPth}
      overriddenPercent={overriddenPercent}
      overriddenValue={overriddenValue}
      stat={stat}
      makeLink={makeLinkAndNavigate}
      loadingPercentiles={loadingPercentiles_stats}
      selectedMetricFilters={selectedMetricFilters}
      selectedPositions={selectedPosition_stats}
    />
  );
};
export default MultitoolConsolidatedStatValueTag;
